import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Location } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, EntityBubbleImage, PlainLinkButton, useEntity, useLang } from '@shapeable/ui';
import { OVERLAY_SHADOW } from '../../data';
import { get } from 'lodash';
import { classNames, locationDMS } from '@shapeable/utils';
import { useMapUtils } from '@shapeable/maps';
import { LocationIcon, LocationIconGlyph } from '@shapeable/icons';
const cls = classNames('location-header-layout');

// -------- Types -------->

export type LocationHeaderLayoutProps = Classable & HasChildren & { 
  entity?: Location;
};

export const LocationHeaderLayoutDefaultProps: Omit<LocationHeaderLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    max-height: 500px;
  `,
});


const BodyStyles = breakpoints({
  base: css`
    justify-self: center;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    color: ${theme.COLOR('light')};
    text-shadow: ${OVERLAY_SHADOW};
  `,
  desktop: css`
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    width: 100%;
    display: flex;
    flex-direction: row; 
    margin-top: ${theme.UNIT(6)};
    margin-bottom: ${theme.UNIT(6)};
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0;
  `,
  tablet: css`
    margin-top: ${theme.UNIT(8)};
  `,
});

const DMSStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0 0;
    color: ${theme.COLOR('light')};
    display: flex;
    font-size: ${theme.FONT_SIZE(13)};
    align-items: center;
    text-align: left;

    &:hover {
      .shp--map-marker-icon__fill {
        fill: ${theme.COLOR('link-hover')};
      }
    }
    `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
    `
})

const DMSIconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(1)};
    width: 27px;
    height: 27px;
  `,
  tablet: css`
    width: 30px;
    height: 30px;
  `,
});

const InfoStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(12)};
    flex-shrink: 1;
  `,
});

const LocationImageStyles = breakpoints({
  base: css`
    width: 60px;
    height: 60px;
    margin-right: ${theme.UNIT(4)};
    box-sizing: border-box;
    margin-top: ${theme.UNIT(1)};
  `,
});



const CellHeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const NameStyles = breakpoints({
  base: css`
    margin: 0;
    line-height: 1.05em;
    font-weight: 500;
    font-size: 1.5em;
  `,
  desktop: css`
    font-size: ${36/16}em;
  `,
});

const LocationIconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(4)};
    box-sizing: border-box;
    width: 65px;
    height: 65px;
    ${theme.FILL('light')}
  `,
  tablet: css`
    width: 100px;
    height: 100px;
  `,
  desktop: css`
    width: 111px;
    height: 111px;
  `
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Body: styled.div`${BodyStyles}`,
  Layout: styled.div`${LayoutStyles}`,
  Info: styled.div`${InfoStyles}`,
    LocationImage: styled(EntityBubbleImage)`${LocationImageStyles}`,
    CellHeader: styled(CellLabel)`${CellHeaderStyles}`,
    Name: styled.h1`${NameStyles}`,
    DMS: styled(PlainLinkButton)`${DMSStyles}`,
    DMSIcon: styled(LocationIconGlyph)`${DMSIconStyles}`,

  LocationIcon: styled(LocationIcon)`${LocationIconStyles}`
};

export const LocationHeaderLayout: Shapeable.FC<LocationHeaderLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { openGraph = {}, name, banner } = entity
  const { image } = openGraph

  const t = useLang()

  const label = t('Place');

  const locationImage = !!get(image, 'url')

  const dms = locationDMS(entity)
  const { showEntity } = useMapUtils();

  const dmsOnClick = () => {
    showEntity(entity, {
      showMap: true,
      entityTypeNames: ['Organisation'],
      zoom: 11,
    });
  };

  return (
   <My.Container 
      className={cls.name(className)}
      >
    <My.Body>
      <My.Layout>
        {
          locationImage ?
          <My.LocationImage imageStyle={'logo'} size={100} entity={openGraph} /> :
          <My.LocationIcon />
        }
        <My.Info>
          <My.CellHeader>{label}</My.CellHeader>
          <My.Name>{name}</My.Name>
          {
            dms && <My.DMS onClick={dmsOnClick}>
              <My.DMSIcon />
              {t('View on Map')}{` ( ${dms} )`}
            </My.DMS>
          }
        </My.Info>

      </My.Layout>
    </My.Body>
   </My.Container>
  )
};

LocationHeaderLayout.defaultProps = LocationHeaderLayoutDefaultProps;
LocationHeaderLayout.cls = cls;