import { graphql } from 'gatsby';
import { LocationLayout } from '../../components/entities/location-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';
export default createGatsbyPageComponent('Location', { layout: LocationLayout });

export const query = graphql`
  query LocationQuery($id: ID!) {
    platform {
      location(id: $id) {
        __typename _schema { label pluralLabel }
        openGraph { description { text(truncate: { words: 20 }) } image { url url2x } }
        id slug path name
          longitude latitude
          name
          id 
          population 
          populationCity 
          densityPerSquareKm 
          medianAge
          gdp
          perCapitaIncome
          age0To19
          age20To49
          age50Plus
          populationMale
          populationFemale
          banner {
            openGraph {
              id
              image {
                id 
                url url2x
              }
            }
          }
          description {
            id
            text(truncate: { words: 100})
          }
          country {
            __typename
            longitude latitude
            id
            name
            openGraph {
              id 
              image {
                id
                url url2x
              }
            }
          }
          quotes {
            __typename
            id slug path name
            description {
              id text
            }
            openGraph {
              id 
              image {
                id
                url url2x
              }
            }
          }
          feedEntries {
            slug id path
            __typename _schema { label pluralLabel }
            name
            url
            summary { id text }
            openGraph { image { url url2x thumbnails { card { url url2x } card { url url2x } } } }
          }
          impactStories {
            __typename
            id slug path name
            description {
              id
              text
            }
          }
          impactResults {
            __typename
            id slug path name
            description {
              id
              text
            }
          }
          organisations {
            __typename
            id name slug path
            openGraph {
              image {
                id url url2x
              }
            }
          }
          people {
            __typename
            id name slug path 
            openGraph {
              image {
                id url url2x
              }
            }
          }
        }
      }
    }
`;